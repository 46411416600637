<template>
  <Modal :open="open" @close="close">
    <div class="flex justify-center">
      <h2 v-if="lead.type == 'renewal'" class="text-2xl font-bold">Suivi du renouvellement</h2>
      <h2 v-else class="text-2xl font-bold">Suivi du lead</h2>
    </div>
    <div class="grid grid-cols-2 gap-6 my-10 text-sm">
      <div>
        <p class="block text-sm font-semibold leading-6 text-gray-900 sm:pt-1.5">Magasin</p>
        <FieldValue class="mt-2" :value="lead.source" />
      </div>
      <div>
        <p class="block text-sm font-semibold leading-6 text-gray-900 sm:pt-1.5">Vendeur</p>
        <FieldValue class="mt-2" :value="lead.seller" />
      </div>
      <div>
        <p class="block text-sm font-semibold leading-6 text-gray-900 sm:pt-1.5">Nom du client</p>
        <FieldValue class="mt-2" :value="lead.name" />
      </div>
      <div>
        <p class="block text-sm font-semibold leading-6 text-gray-900 sm:pt-1.5">Email du client</p>
        <FieldValue class="mt-2" :value="lead.email" />
      </div>
      <div>
        <p class="block text-sm font-semibold leading-6 text-gray-900 sm:pt-1.5">Téléphone du client</p>
        <FieldValue class="mt-2" :value="lead.phone" />
      </div>
      <div>
        <p class="block text-sm font-semibold leading-6 text-gray-900 sm:pt-1.5">Nom du produit</p>
        <FieldValue class="mt-2" :value="lead.productName" />
      </div>
      <div>
        <p class="block text-sm font-semibold leading-6 text-gray-900 sm:pt-1.5">Prix du produit</p>
        <FieldValue class="mt-2" :value="lead.productPrice" />
      </div>
      <div>
        <p class="block text-sm font-semibold leading-6 text-gray-900 sm:pt-1.5">Lien de souscription</p>
        <LeadInfoBox class="mt-2" :textToCopy="lead.subscriptionLink">Copier</LeadInfoBox>
      </div>
      <div>
        <p class="block text-sm font-semibold leading-6 text-gray-900 sm:pt-1.5">Date d'expiration</p>
        <FieldValue class="mt-2" :value="lead.expirationDate" />
      </div>
      <div v-if="isLive">
        <p class="block text-sm font-semibold leading-6 text-gray-900 sm:pt-1.5">Promotion</p>
        <DiscountButton class="mt-2" :discounts="discounts" :lead="lead" @discountUpdated="discountUpdated" />
      </div>
      <FormField
        v-if="isLive"
        id="objections"
        label="Objections"
        name="objections"
        placeholder="Objections"
        v-model="formData.objections"
      />
      <FormField
        v-if="isLive"
        id="comments"
        label="Commentaires"
        name="comments"
        placeholder="Commentaires"
        v-model="formData.comments"
      />
      <FormField
        v-if="isLive"
        id="callbackDate"
        label="Date du prochain appel"
        name="callbackDate"
        type="date"
        v-model="formData.callbackDate"
      />
    </div>
    <div v-if="isLive" class="flex justify-between">
      <GenericButton @onClick="disableLead">Désactiver le lead</GenericButton>
      <GenericButton @onClick="updateLead">Valider</GenericButton>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import LeadInfoBox from "./LeadInfoBox.vue";
import DiscountButton from "@/views/leads/discount/DiscountButton";
import FormField from "@/components/utils/FormField.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import BackofficeService from "@/api/services/backoffice";
import FieldValue from "@/components/utils/FieldValue.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Modal,
    LeadInfoBox,
    DiscountButton,
    FormField,
    GenericButton,
    FieldValue,
  },

  props: ["open", "lead", "discounts"],

  data() {
    return {
      formData: {
        objections: null,
        comments: null,
        callbackDate: null,
        discountId: null,
        status: null,
      },
    };
  },

  computed: {
    isLive() {
      return this.lead.status == "live";
    },
  },

  watch: {
    lead: {
      immediate: true,
      handler(newLead) {
        if (newLead) {
          this.formData.comments = newLead.comments || null;
          this.formData.objections = newLead.objections || null;
          this.formData.callbackDate = newLead.callbackDate || null;
          this.formData.status = newLead.status;
        }
      },
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    discountUpdated(discountId) {
      this.formData.discountId = discountId;
    },
    disableLead() {
      this.formData.status = "disabled";
      this.updateLead();
    },
    async updateLead() {
      try {
        await BackofficeService.updateLead(this.lead.id, this.formData);
        this.$emit("close");
        this.$emit("getLeads");
      } catch (error) {
        await this.notify({
          category: "simple",
          type: "error",
          title: "Something went wrong...",
          text: "This lead is not updated",
        });
      }
    },
  },
};
</script>
