<template>
  <TabsNavigation :tabs="tabs" />
</template>

<script>
import TabsNavigation from "@/components/utils/TabsNavigation.vue";

export default {
  components: { TabsNavigation },

  data: () => ({
    tabs: [
      { name: "partners_organizations", path: "/partners", displayName: "Marchands" },
      { name: "partners_stores", path: "/partners/stores", displayName: "Points de vente" },
    ],
  }),
};
</script>
