<template>
  <Modal v-if="claimHistoricalData !== null" v-bind:open="open" @close="closeModal">
    <div>
      <p class="block text-xl font-medium leading-6 text-gray-900">Status details</p>
      <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium">
        {{ claimHistoricalData.status }}
      </span>

      <div class="bg-gray-100 p-5 mt-5">
        <p class="block text-sm font-medium leading-6 text-gray-900">Estaly Manager Notes</p>
        <p>{{ claimHistoricalData.estalyManagerNotes }}</p>
      </div>

      <div class="bg-gray-100 p-5 mt-5">
        <p class="block text-sm font-medium leading-6 text-gray-900">Claim Manager Notes</p>
        <p>{{ claimHistoricalData.claimManagerNotes }}</p>
      </div>

      <div v-if="claimHistoricalData.clientNotification" class="bg-gray-100 p-5 mt-5">
        <p class="block text-sm font-medium leading-6 text-gray-900">Client Notification</p>
        <div>
          <p>{{ claimHistoricalData.clientNotification }}</p>
        </div>
      </div>

      <div v-if="claimHistoricalData.merchantNotification" class="bg-gray-100 p-5 mt-5">
        <p class="block text-sm font-medium leading-6 text-gray-900">Merchant Notification</p>
        <div>
          <p>{{ claimHistoricalData.merchantNotification }}</p>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/modal/Modal.vue";

export default {
  components: { Modal },
  props: ["open", "claim", "claimHistoricalData"],

  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
