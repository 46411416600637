<template>
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <PageHeader title="Promotions" />
    </div>
    <span class="relative z-0 inline-flex shadow-sm rounded-md space-x-3">
      <button
        class="relative inline-flex rounded-full p-2 text-white shadow-sm text-button-font-primary bg-primary uppercase focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
        @click="this.openModal = true"
      >
        <PlusIcon class="h-5 w-5" aria-hidden="true" />
      </button>
    </span>
  </div>

  <CreateDiscountModal
    v-bind:open-modal="this.openModal"
    @close="this.openModal = false"
    @discountCreated="this.retrieveDiscounts"
  />

  <DiscountListContent v-bind:discounts="this.discounts" />
</template>

<script>
import PageHeader from "@/components/utils/PageHeader.vue";
import BackofficeService from "@/api/services/backoffice";
import { PlusIcon } from "@heroicons/vue/solid";
import CreateDiscountModal from "@/views/discounts/create/CreateDiscountModal.vue";
import DiscountListContent from "@/views/discounts/list/DiscountListContent.vue";

export default {
  components: {
    PageHeader,
    DiscountListContent,
    CreateDiscountModal,
    PlusIcon,
  },

  data() {
    return {
      discounts: [],
      openModal: false,
    };
  },

  watch: {
    openModal(newValue) {
      if (newValue === false) {
        this.retrieveDiscounts();
      }
    },
  },

  async mounted() {
    await this.retrieveDiscounts();
  },

  methods: {
    retrieveDiscounts: async function () {
      const response = await BackofficeService.getDiscounts();
      this.discounts = response.data.discounts;
    },
  },
};
</script>
