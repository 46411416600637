<template>
  <div class="ml-10 mt-2">
    <div class="flex flex-rows mt-2">
      <div>
        <h2 class="text-sm font-bold">Product Categories</h2>
      </div>
      <div>
        <span class="ml-5 relative z-0 inline-flex shadow-sm rounded-md space-x-3">
          <div
            class="relative inline-flex rounded-full p-1 bg-gray-200 text-white shadow-sm hover:bg-gray-100 cursor-pointer"
            @click="addProductCategory"
          >
            <PlusIcon class="h-2 w-2" aria-hidden="true" />
          </div>
        </span>
      </div>
    </div>
    <div>
      <div v-for="(productCategory, index) in productCategories" :key="'productCategory-' + index">
        <div class="text-md font-bold mt-2">{{ productCategory.type }}</div>
        <div class="ml-4 flex flex-col md:flex-row items-center space-x-0 md:space-x-10">
          <div class="flex flex-col">
            <label class="text-xs font-medium leading-6 text-gray-900 sm:pt-1.5">Name</label>
            <input
              class="w-36 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              type="text"
              v-model="productCategory.name"
              :disabled="!productCategory.isEditable"
              :class="{ 'bg-gray-200': !productCategory.isEditable }"
              placeholder="Nom de la catégorie de produit"
            />
          </div>

          <div class="flex flex-col">
            <label class="text-xs font-medium leading-6 text-gray-900 sm:pt-1.5">Marketing page</label>
            <input
              class="w-36 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              type="text"
              v-model="productCategory.marketingPage"
              :disabled="!productCategory.isEditable"
              :class="{ 'bg-gray-200': !productCategory.isEditable }"
              placeholder="Nom de la page marketing"
            />
          </div>

          <div class="mt-2 cursor-pointer" @click="saveProductCategory(productCategory)">
            <CheckIcon class="h-6 w-6" aria-hidden="true" />
          </div>

          <div class="mt-2 cursor-pointer" @click="removeProductCategory(index, productCategory)">
            <TrashIcon class="h-6 w-6" aria-hidden="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PlusIcon, TrashIcon, CheckIcon } from "@heroicons/vue/solid";

export default {
  components: {
    PlusIcon,
    TrashIcon,
    CheckIcon,
  },

  props: ["contractCategory"],

  data() {
    return {
      productCategories: [],
    };
  },
  methods: {
    addProductCategory() {
      this.productCategories.push({
        name: "",
        marketingPage: "",
        isEditable: true,
      });
    },
    removeProductCategory(index, productCategory) {
      this.productCategories.splice(index, 1);
      this.$emit("remove:productCategories", this.contractCategory, productCategory);
    },
    saveProductCategory(productCategory) {
      this.$emit("update:productCategories", this.contractCategory, productCategory);
      productCategory.isEditable = false;
    },
  },
};
</script>
