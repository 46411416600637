<template>
  <template>
    <div>
      <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-30">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div class="fixed z-10 inset-0 overflow-y-auto">
            <div class="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leave-from="opacity-100 translate-y-0 sm:scale-100"
                leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel
                  :class="[
                    'relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6',
                    `sm:${modalWidth}`,
                  ]"
                >
                  <button @click="close" class="absolute top-0 right-0 p-2" tabindex="-1">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <slot></slot>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
  </template>
</template>

<script>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";

export default {
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },
  computed: {
    modalWidth: function () {
      switch (this.size) {
        case "small":
          return "max-w-2xl";
        case "medium":
          return "max-w-4xl";
        case "big":
          return "max-w-6xl";
        default:
          return "max-w-2xl";
      }
    },
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      default: "small",
    },
  },

  methods: {
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
