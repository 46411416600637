<template>
  <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
    <div class="flex-1 flex justify-between">
      <div>
        <p class="text-sm text-gray-700">
          Showing
          {{ " " }}
          <span class="font-medium">{{ startIdx + 1 }}</span>
          {{ " " }}
          to
          {{ " " }}
          <span class="font-medium">{{ endIdx }}</span>
          {{ " " }}
          of
          {{ " " }}
          <span class="font-medium">{{ total }}</span>
          {{ " " }}
          results
        </p>
      </div>
      <div>
        <a
          href="#"
          class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          v-on:click="changePage(currentPage - 1)"
        >
          Previous
        </a>
        <a
          href="#"
          class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          v-on:click="changePage(currentPage + 1)"
        >
          Next
        </a>
      </div>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:hidden">
      <div>
        <p class="text-sm text-gray-700">
          Showing
          {{ " " }}
          <span class="font-medium">{{ startIdx + 1 }}</span>
          {{ " " }}
          to
          {{ " " }}
          <span class="font-medium">{{ endIdx }}</span>
          {{ " " }}
          of
          {{ " " }}
          <span class="font-medium">{{ total }}</span>
          {{ " " }}
          results
        </p>
      </div>
      <div>
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <div
            class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
            v-on:click="changePage(currentPage - 1)"
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </div>
          <div
            v-for="page in pages"
            v-bind:key="page"
            v-bind:class="[
              page === currentPage
                ? 'z-10 border-primary text-primary-dark'
                : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
              'relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer',
            ]"
            v-on:click.prevent="changePage(page)"
          >
            {{ page }}
          </div>
          <!-- Current: "z-10 bg-blue-50 border-primary text-blue-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
          <!-- <a
            href="#"
            aria-current="page"
            class="z-10 bg-blue-50 border-primary text-blue-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
          >
            1
          </a>
          <a
            href="#"
            class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
          >
            2
          </a>
          <a
            href="#"
            class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
          >
            3
          </a>
          <span
            class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
          >
            ...
          </span>
          <a
            href="#"
            class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
          >
            8
          </a>
          <a
            href="#"
            class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
          >
            9
          </a>
          <a
            href="#"
            class="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
          >
            10
          </a> -->
          <div
            class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
            v-on:click="changePage(currentPage + 1)"
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },

  props: {
    pages: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    startIdx: {
      type: Number,
      required: true,
    },
    endIdx: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },

  methods: {
    changePage(goToPage) {
      if (goToPage === this.currentPage || goToPage > this.pages[this.pages.length - 1] || goToPage < this.pages[0])
        return;
      this.$emit("changePage", goToPage);
    },
  },
};
</script>
