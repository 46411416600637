<template>
  <div class="border-b border-gray-900/10 pb-10">
    <div>
      <div>
        <p class="font-sm font-thin text-gray-500">
          Voici les catégories de produits disponibles : {{ productCategories }}
        </p>
      </div>
      <div>
        <p class="font-sm font-thin text-gray-500">Voici les pages marketing disponibles : {{ marketingPages }}</p>
      </div>
    </div>
    <div class="flex flex-rows justify-between">
      <div>
        <label for="contract" class="block text-sm font-medium leading-6 text-gray-900"></label>
        <select
          v-model="selectedContract"
          id="contract"
          name="contract"
          class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
        >
          <option v-for="contractCategory in contractCategories" :key="contractCategory" :value="contractCategory">
            {{ this.format(contractCategory) }}
          </option>
        </select>
      </div>
      <div>
        <span class="mt-2 relative z-0 inline-flex shadow-sm rounded-md space-x-3">
          <div
            class="relative inline-flex rounded-full p-2 bg-gray-400 text-white shadow-sm hover:bg-gray-200 cursor-pointer"
            @click="addGroup"
          >
            <PlusIcon class="h-3 w-3" aria-hidden="true" />
          </div>
        </span>
      </div>
    </div>
    <div>
      <div v-for="(group, index) in contracts" :key="'group-' + index">
        <div class="text-md font-bold mt-8">
          {{ group.contractCategory.charAt(0).toUpperCase() + group.contractCategory.slice(1) }}
        </div>
        <div class="flex flex-col">
          <div class="ml-4 flex flex-col md:flex-row md:items-center space-x-10">
            <div class="mt-2 cursor-pointer" @click="removeGroup(index)">
              <TrashIcon class="h-6 w-6" aria-hidden="true" />
            </div>
            <div class="flex flex-col">
              <label class="text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Store Fee</label>
              <input
                class="w-36 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                type="text"
                v-model="group.storeFee"
                placeholder="Store Fee"
              />
            </div>

            <div class="flex flex-col">
              <label class="text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Distribution Fee</label>
              <input
                class="w-36 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                type="text"
                v-model="group.distributionFee"
                placeholder="Distribution Fee"
              />
            </div>
          </div>
          <ProductCategory
            v-bind:contractCategory="group.name"
            @update:productCategories="addProductCategory"
            @remove:productCategories="removeProductCategory"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PlusIcon, TrashIcon } from "@heroicons/vue/solid";
import ProductCategory from "@/views/stores/create/components/ProductCategory.vue";

export default {
  components: {
    PlusIcon,
    TrashIcon,
    ProductCategory,
  },

  props: ["contractCategories", "productCategories", "marketingPages"],

  data() {
    return {
      selectedContract: "",
      contracts: [],
    };
  },

  methods: {
    format: (str) => {
      str = str
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      return str;
    },
    addGroup() {
      this.contracts.push({
        contractCategory: this.selectedContract,
        storeFee: "",
        distributionFee: "",
        productCategories: [],
      });
    },
    removeGroup(index) {
      this.contracts.splice(index, 1);
    },
    addProductCategory(contractCategory, newValue) {
      let targetGroup = this.contracts.find((group) => group.name === contractCategory);
      if (targetGroup) {
        targetGroup.productCategories.push(newValue);
      }
    },

    removeProductCategory(contractCategory, newValue) {
      let targetGroup = this.contracts.find((group) => group.name === contractCategory);

      let productCategory = targetGroup.productCategories.find(
        (group) => group.name === newValue.name && group.marketingPage === newValue.marketingPage
      );

      if (productCategory) {
        let index = targetGroup.productCategories.indexOf(productCategory);

        if (index !== -1) {
          targetGroup.productCategories.splice(index, 1);
        }
      }
    },
  },

  watch: {
    contracts: {
      handler(newContracts) {
        this.$emit("update:contracts", newContracts);
      },
      deep: true,
    },
  },
};
</script>
