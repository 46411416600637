<template>
  <div>
    <div class="sm:w-1/4">
      <DateRangePicker class="my-6" placeholder="Sélectionner une période" v-model="dateRange" use-query-params />
    </div>
    <SearchBar
      class="my-6"
      :placeholder="$t('SearchPlanSales')"
      :current-page="currentPage"
      :available-filters="availableFilters"
      :selected-filters="selectedFilters"
      :initial-keywords="keywords"
      @updateFilterSearch="updateFilterSearch"
      @updateKeywordSearch="updateKeywordSearch"
    />
  </div>
</template>

<script>
import DateRangePicker from "@/components/utils/DateRangePicker.vue";
import SearchBar from "@/components/menu/SearchBar.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: { SearchBar, DateRangePicker },

  props: ["currentPage"],

  computed: {
    ...mapState("plan_sale_list", ["availableFilters", "selectedFilters", "keywords", "startDate", "endDate"]),

    dateRange: {
      get() {
        return [this.startDate, this.endDate];
      },
      set(value) {
        if (value && value.length === 2) {
          this.handleNewDateSelected(value[0], value[1]);
        } else {
          this.handleNewDateSelected(null, null);
        }
      },
    },
  },

  methods: {
    ...mapActions("plan_sale_list", ["updateFilter", "updateSearch", "updateDateFilter"]),

    updateKeywordSearch(search) {
      const newPage = 1;
      this.updatePage(newPage);
      this.updateSearch({ searchString: search, page: newPage });
    },

    updateFilterSearch(filterName, filterOptions) {
      const newPage = 1;
      this.updatePage(newPage);
      this.updateFilter({
        filterName: filterName,
        filterOptions: filterOptions,
        page: 1,
      });
    },

    handleNewDateSelected(startDate, endDate) {
      const newPage = 1;
      this.updatePage(newPage);
      this.updateDateFilter({
        startDate,
        endDate,
        page: 1,
      });
    },

    updatePage(newPage) {
      this.$emit("updatePage", newPage, false);
    },
  },
};
</script>
