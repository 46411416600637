<template>
  <div
    class="mt-4 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0"
  >
    <div class="sm:py-2 flex items-center">
      <OrganizationSelector
        class="w-full mb-2"
        @organizationSelectorSelectedOrganizationId="handleSelectedOrganizationId"
      />
    </div>
  </div>
</template>

<script>
import OrganizationSelector from "@/views/stores/create/selector/OrganizationSelector.vue";

export default {
  components: {
    OrganizationSelector,
  },

  data() {
    return {
      selectedOrganizationId: "",
    };
  },

  watch: {
    selectedOrganizationId: function (newValue) {
      this.$emit("update:selectedOrganizationId", newValue);
    },
  },

  methods: {
    handleSelectedOrganizationId(organizationSelectorSelectedOrganizationId) {
      this.selectedOrganizationId = organizationSelectorSelectedOrganizationId;
    },
  },
};
</script>
