<template>
  <div v-if="this.anyProductSelected" class="bg-yellow-50 border-l-4 border-yellow-400 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <p class="text-sm text-yellow-700">
          {{ this.numberOfSelectedProducts }} {{ $t("Products Selected") }}
          <a @click="unselectAll" class="font-medium underline text-yellow-700 hover:text-yellow-600">
            {{ $t("Unselect All") }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from "@heroicons/vue/solid";
export default {
  components: {
    ExclamationIcon,
  },
  computed: {
    anyProductSelected: function () {
      return this.selectedProducts.length !== 0;
    },
    numberOfSelectedProducts: function () {
      return this.selectedProducts.length;
    },
  },
  props: ["selectedProducts", "products"],
  methods: {
    unselectAll: function () {
      this.$emit("unselectAll");
    },
  },
};
</script>
