<template>
  <div>
    <Header />
    <BusinessAnalytics />
  </div>
</template>

<script>
import Header from "@/components/analytics/Header.vue";
import BusinessAnalytics from "@/components/analytics/business/Analytics.vue";

export default {
  components: {
    Header,
    BusinessAnalytics,
  },
};
</script>
