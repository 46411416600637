<template>
  <Tabs :tabs="tabsWithCurrent" @tab-selected="goTo" />
</template>

<script>
import Tabs from "@/components/utils/Tabs.vue";

export default {
  components: { Tabs },

  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },

  computed: {
    tabsWithCurrent() {
      return this.tabs.map((tab) => {
        return { ...tab, current: this.isCurrent(tab) };
      });
    },
  },

  methods: {
    goTo(tab) {
      this.$router.push({ path: tab.path, query: {} });
    },

    isCurrent(tab) {
      if (this.$route.path == "/" && tab.path == "/analytics/business") return true;

      return this.$route.matched[0].path === tab.path;
    },
  },
};
</script>
