<template>
  <SwitchGroup as="div" class="flex items-center justify-between">
    <span class="flex grow flex-col p-1.5">
      <SwitchLabel as="span" class="text-sm/6 font-medium text-gray-900" passive>{{ label }}</SwitchLabel>
      <SwitchDescription v-if="description" as="span" class="text-sm text-gray-500">
        {{ description }}
      </SwitchDescription>
    </span>
    <Switch
      v-model="internalValue"
      :class="[
        internalValue ? 'bg-primary' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2',
      ]"
    >
      <span
        aria-hidden="true"
        :class="[
          internalValue ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]"
        style="width: 50%; height: 100%"
      />
    </Switch>
  </SwitchGroup>
</template>

<script>
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from "@headlessui/vue";

export default {
  components: {
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
  },
  props: {
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
