<template>
  <div class="flex">
    <h1 class="text-3xl pb-4 font-extrabold tracking-tight text-gray-900">
      {{ $t(title) }}
    </h1>
    <slot />
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
