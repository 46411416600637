<template>
  <div v-if="claim.invoice" class="bg-gray shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">Facture de réparation</h3>
    </div>
    <div class="border-t border-gray-200">
      <dl>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200">
          <dt class="text-sm font-medium text-gray-500">Facture</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <div class="border border-gray-200 rounded-md divide-y divide-gray-200">
              <div class="pl-3 pr-4 py-3 flex">
                <div class="justify-between text-sm w-0 flex-1 flex">
                  <PaperClipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span class="ml-2 flex-1 w-0 truncate">{{ claim.invoice.fileName }}</span>
                  <a
                    class="inline-flex p-1.5 border border-transparent rounded-full shadow-sm text-white bg-primary"
                    :href="claim.invoice.path"
                    target="_blank"
                  >
                    <EyeIcon class="h-5 w-5" aria-hidden="true" />
                  </a>
                </div>
              </div>
            </div>
          </dd>
        </div>
      </dl>
      <dl>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t("Price") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <span class="ml-2 flex-1 w-0 truncate">
              {{ claim.invoice.price }}
            </span>
          </dd>
        </div>
      </dl>
      <dl>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t("Status") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <span class="ml-2 flex-1 w-0 truncate">
              {{ $t(`${claim.invoice.approvalStatus}`) }}
            </span>
          </dd>
        </div>
      </dl>
      <dl>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t("Voir le détail de la facture") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <button
              class="inline-flex p-1.5 border border-transparent rounded-full shadow-sm text-white bg-primary"
              :href="claim.invoice.path"
              @click="openInvoiceCostDetails = true"
            >
              <EyeIcon class="h-5 w-5" aria-hidden="true" />
            </button>
            <InvoiceCostDetails
              :invoice="claim.invoice"
              :open="openInvoiceCostDetails"
              @close="openInvoiceCostDetails = false"
            />
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { EyeIcon, PaperClipIcon } from "@heroicons/vue/solid";
import InvoiceCostDetails from "@/components/claim/details/invoice/InvoiceCostDetails.vue";

export default {
  components: {
    InvoiceCostDetails,
    PaperClipIcon,
    EyeIcon,
  },
  props: ["claim"],
  data() {
    return {
      openInvoiceCostDetails: false,
    };
  },
};
</script>
