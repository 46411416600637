<template>
  <div>
    <div class="bg-yellow-50 border-l-4 border-yellow-400 text-yellow-700 p-4 mt-5 mb-4 sm:mt-6">
      <div class="flex flex-row items-center">
        <ExclamationIcon class="w-8 h-8 text-yellow-400" />
        <p class="ml-4">Une action est requise de votre part</p>
      </div>
      <div class="pl-12">
        <p class="cursor-pointer underline font-bold" v-if="approveRepairQuotation" @click="this.openModal = true">
          Veuillez approuver ou refuser le devis de réparation
        </p>
        <p class="cursor-pointer underline font-bold" v-else-if="approveInvoice" @click="this.openModal = true">
          Veuillez approuver ou refuser la facture de réparation
        </p>
      </div>
    </div>
    <div class="mt-10 shadow overflow-hidden sm:rounded-lg text-center mt-2">
      <ActionModal v-bind:open="this.openModal" v-bind:claim="claim" @close="this.openModal = false" />
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from "@heroicons/vue/solid";
import ActionModal from "@/components/claim/details/action/ActionModal";

export default {
  components: {
    ExclamationIcon,
    ActionModal,
  },

  props: ["claim"],

  data() {
    return {
      openModal: false,
    };
  },

  computed: {
    approveRepairQuotation() {
      return this.claim.requiredAction === "approve_quotation";
    },

    approveInvoice() {
      return this.claim.requiredAction === "approve_invoice";
    },
  },
};
</script>
