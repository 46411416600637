<template>
  <Modal v-bind:open="this.openModal" @close="this.closeModal">
    <form>
      <div class="space-y-4">
        <FormField id="name" label="Name" name="name" placeholder="Name" v-model="organization.name" />
        <FormField
          id="business_name"
          label="Business name"
          name="business_name"
          placeholder="Business name"
          v-model="organization.businessName"
        />
        <FormField
          id="billing_address"
          label="Billing address"
          name="billing_address"
          placeholder="Billing address"
          v-model="organization.billingAddress"
        />
        <FormField
          id="registration_number"
          label="Registration number"
          name="registration_number"
          placeholder="Registration number"
          v-model="organization.registrationNumber"
        />
        <FormField
          id="total_points_of_sale"
          label="Total points of sale"
          name="total_points_of_sale"
          placeholder="Total points of sale"
          v-model="organization.totalPointsOfSale"
        />
        <FormField
          id="logo_url"
          label="Logo url"
          name="logo_url"
          placeholder="Logo url"
          v-model="organization.logoUrl"
        />
        <Selector
          id="invoice_level"
          label="Invoice Level"
          name="invoice_level"
          v-model="organization.invoiceLevel"
          :options="invoiceLevels"
        />
        <Selector
          id="revenue_range"
          label="Revenue range"
          name="revenue_range"
          v-model="organization.revenueRange"
          :options="revenueRanges"
        />
        <Selector
          id="network_type"
          label="Network type"
          name="network_type"
          v-model="organization.networkType"
          :options="networkTypes"
        />
      </div>
      <div class="mt-6 flex items-center justify-end gap-x-6">
        <button type="button" class="text-sm font-semibold leading-6 text-gray-900" @click="this.closeModal">
          Annuler
        </button>
        <div
          type="submit"
          class="cursor-pointer rounded-md px-3 py-2 text-sm font-semibold text-button-font-primary bg-primary uppercase shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          @click="createOrganization"
        >
          Créer
        </div>
      </div>
    </form>
  </Modal>
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import FormField from "@/components/utils/FormField.vue";
import Modal from "@/components/modal/Modal.vue";
import Selector from "@/components/utils/Selector.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Modal,
    Selector,
    FormField,
  },
  props: ["openModal"],

  data() {
    return {
      organization: {
        name: "",
        businessName: "",
        billingAddress: "",
        networkType: "",
        revenueRange: "",
        registrationNumber: "",
        totalPointsOfSale: "",
        invoiceLevel: "",
        logoUrl: "",
      },
      invoiceLevels: [],
      revenueRanges: [],
      networkTypes: [],
    };
  },

  created() {
    this.fetchOptions();
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    closeModal: function () {
      this.$emit("close");
    },

    async fetchOptions() {
      try {
        const response = await BackofficeService.getOrganizationOptions();
        this.invoiceLevels = response.data.invoice_levels;
        this.revenueRanges = response.data.revenue_ranges;
        this.networkTypes = response.data.network_types;
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Error fetching options",
          text: error.message,
        });
      }
    },

    createOrganization: async function () {
      let organizationData = {
        organization: this.organization,
      };
      try {
        await BackofficeService.createOrganization(organizationData);
        this.notify({
          category: "simple",
          type: "success",
          title: "Organization is created.",
        });
        this.closeModal();
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: error?.response?.data?.error,
        });
      }
    },
  },
};
</script>
