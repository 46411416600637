<template>
  <Datepicker
    :placeholder="placeholder"
    class="text-sm w-full rounded-md"
    v-model="internalValue"
    range
    multiCalendars
    :presetRanges="presetRanges"
    autoApply
    :enableTimePicker="false"
    :format="datePickerFormat"
    locale="fr"
    :max-date="new Date()"
  />
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import { format } from "date-fns";
import {
  currentMonthRange,
  currentWeekRange,
  previousMonthRange,
  previousWeekRange,
  currentMonth,
  previousMonth,
  currentWeek,
  previousWeek,
} from "@/utils/date";

export default {
  components: { Datepicker },

  props: {
    placeholder: {
      type: String,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    useQueryParams: {
      type: Boolean,
      default: false, // Store values in query params (start_date, end_date), useful for navigation persistence/link sharing
    },
    ranges: {
      type: Array,
      default: () => [],
    },
  },

  beforeMount() {
    if (this.useQueryParams) this.loadQueryParams();
  },

  data: () => {
    return {
      defaultRanges: [
        {
          id: 1,
          label: "Mois en cours",
          range: currentMonthRange(),
        },
        {
          id: 2,
          label: "Semaine en cours",
          range: currentWeekRange(),
        },
        {
          id: 3,
          label: "Mois précédent",
          range: previousMonthRange(),
        },
        {
          id: 4,
          label: "Semaine précédente",
          range: previousWeekRange(),
        },
      ],
    };
  },

  computed: {
    presetRanges() {
      const extraRanges = this.ranges.map((range, index) => {
        return {
          id: index + this.defaultRanges.length + 1,
          label: range.label,
          range: range.range,
        };
      });
      return [...this.defaultRanges, ...extraRanges];
    },

    internalValue: {
      get() {
        return this.modelValue?.every((el) => el == null) ? null : this.modelValue;
      },
      set(value) {
        let startDate = null;
        let endDate = null;
        if (value) {
          startDate = this.formatDate(value[0]);
          endDate = this.formatDate(value[1]);
        }
        if (this.useQueryParams) this.updateQueryParams(startDate, endDate);
        this.$emit("update:modelValue", [startDate, endDate]);
      },
    },
  },

  methods: {
    formatDate(date) {
      return format(date, "yyyy-MM-dd");
    },

    datePickerFormat(date) {
      const startDate = date[0];
      const endDate = date[1];
      if (!startDate || !endDate) return "";

      if (startDate instanceof Date && endDate instanceof Date) {
        if (currentMonth(startDate, endDate)) return "Mois en cours";
        if (previousMonth(startDate, endDate)) return "Mois précédent";
        if (currentWeek(startDate, endDate)) return "Semaine en cours";
        if (previousWeek(startDate, endDate)) return "Semaine précédente";
      }

      const from = format(startDate, "dd/MM/yyyy");
      const to = format(endDate, "dd/MM/yyyy");
      return `Du ${from} au ${to}`;
    },

    loadQueryParams() {
      const startDate = this.$route.query.start_date;
      const endDate = this.$route.query.end_date;
      if (startDate && endDate) this.internalValue = [new Date(startDate), new Date(endDate)];
    },

    updateQueryParams(startDate, endDate) {
      const query = { ...this.$route.query };
      if (!startDate || !endDate) {
        delete query.start_date;
        delete query.end_date;
      } else {
        query.start_date = startDate;
        query.end_date = endDate;
      }
      this.$router.push({ query });
    },
  },
};
</script>
