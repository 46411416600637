<template>
  <div>
    <SearchBar
      :current-page="currentPage"
      :placeholder="$t('OrganisationSearch')"
      :initial-keywords="keywords"
      :available-filters="availableFilters"
      :selected-filters="selectedFilters"
      @updateKeywordSearch="updateKeywordSearch"
      @updateFilterSearch="updateFilterSearch"
    />
    <div class="mt-10 relative overflow-x-auto">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr class="hidden md:table-row">
            <th scope="col" class="px-6 py-3">{{ $t("Id") }}</th>
            <th scope="col" class="px-6 py-3">{{ $t("Name") }}</th>
            <th scope="col" class="px-6 py-3">{{ $t("Channel") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="organization in organizations"
            :key="organization.id"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-200 cursor-pointer"
            @click="redirectToOrganizationDetailsPage(organization.id)"
          >
            <td class="px-6 py-4 hidden md:table-cell">
              {{ organization.id }}
            </td>
            <td class="px-6 py-4 hidden md:table-cell">
              {{ organization.name }}
            </td>
            <td class="px-6 py-4 hidden md:table-cell">
              {{ $t(organization.source) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination
      :currentPage="currentPage"
      :pages="pages"
      :startIdx="startIdx"
      :endIdx="endIdx"
      :total="totalNumberOfOrganizations"
      v-on:changePage="handleChangePage"
      v-if="pages.length > 1"
    />
  </div>
</template>

<script>
import BackofficeService from "@/api/services/backoffice";
import Pagination from "@/components/utils/Pagination.vue";
import SearchBar from "@/components/menu/SearchBar.vue";
import { isPresent } from "@/utils/validation";
import { mapMutations, mapState } from "vuex";

export default {
  components: { SearchBar, Pagination },

  async beforeMount() {
    this.loadQueryParams();
    await this.retrieveOrganizations();
  },

  data() {
    return {
      organizations: [],
      currentPage: 1,
      keywords: null,
      totalNumberOfOrganizations: 1,
      availableFilters: {
        sources: [],
      },
      selectedFilters: {
        sources: [],
      },
      pageSize: 1,
    };
  },

  computed: {
    ...mapState("backoffice", ["isLoading"]),

    startIdx() {
      return (this.currentPage - 1) * this.pageSize;
    },

    endIdx() {
      return Math.min(this.startIdx + this.pageSize, this.totalNumberOfOrganizations);
    },

    pages() {
      if (this.isLoading || this.totalNumberOfOrganizations < this.pageSize) {
        return [];
      }
      return [...Array(Math.ceil(this.totalNumberOfOrganizations / this.pageSize)).keys()].map((e) => e + 1);
    },

    sources: {
      get() {
        return this.selectedFilters.sources;
      },
      set(value) {
        if (!isPresent(value)) return (this.selectedFilters.sources = []);

        this.selectedFilters.sources = Array.isArray(value) ? value : [value];
      },
    },

    searchParams() {
      return { ...this.$route.query, sources: this.sources };
    },
  },

  methods: {
    ...mapMutations("backoffice", ["setIsLoading"]),

    async retrieveOrganizations() {
      let response = null;
      let timeout = null;

      try {
        timeout = setTimeout(() => {
          this.setIsLoading(true);
        }, 200);
        response = await BackofficeService.getOrganizations(this.searchParams);
        this.organizations = response.data.organizations;
        this.totalNumberOfOrganizations = response.data.total_number_of_organizations;
        this.availableFilters = response.data.filters;
        this.pageSize = response.data.page_size;
      } catch (error) {
        console.error(error);
      } finally {
        clearTimeout(timeout);
        this.setIsLoading(false);
      }
    },

    updateRouteQueryParams() {
      const query = {};
      this.page = this.currentPage;
      if (this.keywords) query.keywords = this.keywords;
      if (this.sources) query.sources = this.sources;
      this.$router.push({ query });
    },

    loadQueryParams() {
      this.currentPage = this.$route.query.page;
      this.keywords = this.$route.query.keywords;
      this.sources = this.$route.query.sources;
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.updateRouteQueryParams();
    },

    updateKeywordSearch(keywords) {
      this.keywords = keywords;
      this.currentPage = 1;
      this.updateRouteQueryParams();
    },

    updateFilterSearch(filterName, filterOptions) {
      this.selectedFilters[filterName] = filterOptions;
      this.currentPage = 1;
      this.updateRouteQueryParams();
    },

    redirectToOrganizationDetailsPage(organizationId) {
      this.$router.push(`/organizations/${organizationId}`);
    },
  },

  watch: {
    "$route.query"() {
      this.retrieveOrganizations();
    },
  },
};
</script>
