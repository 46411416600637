import { securedAxiosInstance } from "../axios";

class AnalyticsService {
  getOrganizations(startDate, endDate, sortParams, salesFieldManagerId) {
    return securedAxiosInstance.get("/backoffice/analytics/retail/organizations", {
      params: {
        sortColumn: sortParams["column"],
        sortOrder: sortParams["order"],
        sales_field_manager_id: salesFieldManagerId,
        start: startDate,
        end: endDate,
      },
    });
  }

  getStores(organizationId, startDate, endDate, sortParams, salesFieldManagerId) {
    return securedAxiosInstance.get("/backoffice/analytics/retail/stores", {
      params: {
        organization_id: organizationId,
        sortColumn: sortParams["column"],
        sortOrder: sortParams["order"],
        sales_field_manager_id: salesFieldManagerId,
        start: startDate,
        end: endDate,
      },
    });
  }

  getSellers(storeId, startDate, endDate, sortParams, salesFieldManagerId) {
    return securedAxiosInstance.get("/backoffice/analytics/retail/sellers", {
      params: {
        store_id: storeId,
        sortColumn: sortParams["column"],
        sortOrder: sortParams["order"],
        sales_field_manager_id: salesFieldManagerId,
        start: startDate,
        end: endDate,
      },
    });
  }

  getBusinessData(organizationId, storeId, startDate, endDate) {
    return securedAxiosInstance.get(
      `/backoffice/analytics` +
        `?organization_ids=${organizationId}&store_ids=${storeId}&start=${startDate}&end=${endDate}`
    );
  }
}

export default new AnalyticsService();
