<template>
  <button
    class="relative inline-flex items-center pl-1 pr-2 py-1 text-sm tracking-wide border border-transparent shadow-sm font-medium text-button-font-primary bg-primary rounded-lg"
    :disabled="loading || disabled"
  >
    <PlusIcon class="h-5 w-5 mr-1" aria-hidden="true" />
    <slot></slot>
  </button>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/solid";

export default {
  components: {
    PlusIcon,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
