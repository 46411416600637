<template>
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="text-3xl pb-3 pl-2 font-extrabold tracking-tight text-gray-900">
        {{ $t("Stores") }}
      </h1>
    </div>
    <span class="relative z-0 inline-flex shadow-sm rounded-md space-x-3 my-2 md:my-0 ml-2 md:ml-0">
      <button
        class="relative inline-flex rounded-full text-button-font-primary bg-primary uppercase p-2 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        @click="this.openModal = true"
      >
        <PlusIcon class="h-5 w-5" aria-hidden="true" />
      </button>
    </span>
  </div>

  <CreateStoreModal v-bind:open-modal="this.openModal" @close="this.openModal = false" />

  <div>
    <StoreList />
  </div>
</template>

<script>
import StoreList from "@/views/stores/StoreList.vue";
import CreateStoreModal from "@/views/stores/create/CreateStoreModal.vue";
import { PlusIcon } from "@heroicons/vue/solid";

export default {
  components: {
    StoreList,
    CreateStoreModal,
    PlusIcon,
  },
  data() {
    return {
      openModal: false,
    };
  },
};
</script>
