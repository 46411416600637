<template>
  <div v-if="this.discount" class="bg-gray-100 shadow sm:rounded-lg">
    <div @click="this.toggleVisibility" class="flex justify-between items-center px-4 py-5 sm:px-6 cursor-pointer">
      <h3 class="text-lg leading-6 font-bold text-gray-900">Promotions & Challenge</h3>
      <div class="w-10">
        <ChevronDownIcon v-if="!this.isVisible" />
        <ChevronUpIcon v-else />
      </div>
    </div>
    <div v-if="this.isVisible" class="border-t border-gray-200">
      <dl class="bg-white px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
        <div>
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Discount") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <DiscountButton
              v-bind:current-discount="this.discount.title"
              v-bind:store-id="storeId"
              v-bind:discounts="this.discount.availableDiscounts"
              @discountUpdated="this.discountUpdated"
            />
          </dd>
        </div>
        <div v-if="this.challenges">
          <dt class="text-sm font-bold text-gray-500">
            {{ $t("Challenge") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <ChallengeButton
              v-bind:challenges="this.challenges.availableChallenges"
              v-bind:store-id="this.storeId"
              v-bind:current-challenge-id="this.challenges.currentChallengeId"
              @challengeUpdated="this.challengeUpdated()"
            />
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/outline";
import DiscountButton from "@/views/stores/discount/DiscountButton.vue";
import ChallengeButton from "@/views/stores/challenge/ChallengeButton.vue";

export default {
  components: {
    DiscountButton,
    ChallengeButton,
    ChevronUpIcon,
    ChevronDownIcon,
  },
  props: ["discount", "challenges", "storeId"],
  data() {
    return {
      isVisible: false, // Initial visibility state
    };
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible; // Toggle the visibility state
    },

    discountUpdated() {
      this.$emit("discountUpdated");
    },

    challengeUpdated() {
      this.$emit("challengeUpdated");
    },
  },
};
</script>
