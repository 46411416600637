<template>
  <div>
    <div class="flex flex-col sm:flex-row sm:justify-between items-start mb-6">
      <div class="flex flex-col w-full sm:w-1/2 mt-4 sm:mt-1">
        <div class="flex justify-between">
          <p class="font-bold">Sélectionner une période</p>
          <button @click="resetToBeginning" class="text-primary-dark underline text-sm">Depuis le début</button>
        </div>
        <DateRangePicker v-model="date" @update:model-value="updateDate" />
      </div>
      <div class="w-full mt-4 sm:mt-1 sm:w-1/4" v-if="isObjectValid(salesFieldManagers)">
        <p class="font-bold">Sélectionner un animateur</p>
        <SalesFieldManagerSelector
          :salesFieldManagers="salesFieldManagers"
          @update:selectedSalesFieldManager="onSalesFieldManagerChange"
        />
      </div>
    </div>

    <div class="min-h-5 mb-3" v-if="storesView || sellersView">
      <a
        href="#"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        @click="goBack"
      >
        Précédent
      </a>
    </div>
    <CommonAnalyticsTable
      :stats="stats"
      :total="total"
      :currentMonth="currentMonth"
      :statsClickable="organizationsView || storesView"
      :showTargetColumns="true"
      :currentSortColumn="sortParams.column"
      :currentSortOrder="sortParams.order"
      @rowClicked="rowClicked"
      @sortChanged="sortChanged"
    />
  </div>
</template>

<script>
import DateRangePicker from "@/components/utils/DateRangePicker.vue";
import { currentMonthRange } from "@/utils/date";
import CommonAnalyticsTable from "@/components/analytics/retail/utils/CommonAnalyticsTable.vue";
import SalesFieldManagerSelector from "@/components/analytics/retail/utils/SalesFieldManagerSelector.vue";
import { isObjectValid } from "@/utils/validation";
import { mapMutations } from "vuex";
import { formatResponse } from "@/utils/backend_response_formatter";
import { formatAnalyticsDate } from "@/utils/date_formatter";

import "@vuepic/vue-datepicker/dist/main.css";
import { mapState, mapActions } from "vuex";
import AnalyticsService from "@/api/services/analytics";

export default {
  components: {
    DateRangePicker,
    CommonAnalyticsTable,
    SalesFieldManagerSelector,
  },

  mounted() {
    if (this.signedIn) {
      this.loadQueryParams();
      if (!this.$route.query.start) {
        // If no date is set, set the current month
        this.date = currentMonthRange();
        this.updateCurrentMonth(this.date[1]);
      }
      this.getAnalytics();
    } else {
      this.$router.push("/signin");
    }
  },

  data: () => {
    return {
      organizationId: null,
      storeId: null,
      stats: null,
      total: null,
      sortParams: {
        column: null,
        order: null,
      },
      salesFieldManagerId: null,
      salesFieldManagers: [],
      date: currentMonthRange(),
      currentMonth: "",
    };
  },

  computed: {
    ...mapState("auth", ["signedIn"]),

    organizationsView() {
      return !this.storesView && !this.sellersView;
    },

    storesView() {
      return !this.sellersView && this.organizationId != null;
    },

    sellersView() {
      return this.storeId != null;
    },

    sinceBeginningStartDate() {
      return new Date(2022, 1, 1);
    },

    sinceBeginningEndDate() {
      return new Date();
    },
  },

  created() {
    this.getOrganizations();
  },

  methods: {
    ...mapActions("backoffice", ["getOrganizations"]),
    ...mapMutations("backoffice", ["setIsLoading"]),

    isObjectValid,

    stringFormat(string) {
      return string.split("_").join(" ").charAt(0).toUpperCase() + string.split("_").join(" ").slice(1);
    },

    async getAnalytics() {
      this.setIsLoading(true);
      try {
        const start = Array.isArray(this.date) && this.date[0] ? formatAnalyticsDate(this.date[0]) : null;
        const end = Array.isArray(this.date) && this.date[1] ? formatAnalyticsDate(this.date[1]) : null;

        const salesFieldManagerId = this.salesFieldManagerId ? this.salesFieldManagerId : "";

        let response;
        if (this.sellersView) {
          response = await AnalyticsService.getSellers(this.storeId, start, end, this.sortParams, salesFieldManagerId);
        } else if (this.storesView) {
          response = await AnalyticsService.getStores(
            this.organizationId,
            start,
            end,
            this.sortParams,
            salesFieldManagerId
          );
        } else {
          response = await AnalyticsService.getOrganizations(start, end, this.sortParams, salesFieldManagerId);
        }

        const formattedResponse = formatResponse(response.data);
        this.stats = formattedResponse.stats;
        this.total = formattedResponse.total;
        this.salesFieldManagers = formattedResponse.salesFieldManagers;
      } catch (error) {
        console.error(error);
      } finally {
        this.setIsLoading(false);
      }
    },

    updateQueryParams() {
      const query = {};
      if (this.date && this.date.length == 2) {
        query.start = formatAnalyticsDate(this.date[0]);
        query.end = formatAnalyticsDate(this.date[1]);
      }
      if (this.salesFieldManagerId) query.salesFieldManagerId = this.salesFieldManagerId;
      if (this.organizationId) query.organizationId = this.organizationId;
      if (this.storeId) query.storeId = this.storeId;
      if (this.sortParams.column) query.column = this.sortParams.column;
      if (this.sortParams.order) query.order = this.sortParams.order;
      this.$router.push({ query });
    },

    loadQueryParams() {
      this.organizationId = this.$route.query.organizationId;
      this.storeId = this.$route.query.storeId;
      this.salesFieldManagerId = this.$route.query.salesFieldManagerId;
      this.date = [
        this.$route.query.start ? new Date(this.$route.query.start) : this.sinceBeginningStartDate,
        this.$route.query.end ? new Date(this.$route.query.end) : this.sinceBeginningEndDate,
      ];
      if (this.date[0] && this.date[1]) this.updateCurrentMonth(this.date[1]);
      this.sortParams = {
        column: this.$route.query.column,
        order: this.$route.query.order,
      };
    },

    goBack() {
      if (this.sellersView) {
        this.storeId = null;
      } else if (this.storesView) {
        this.storeId = null;
        this.organizationId = null;
      }
      this.updateQueryParams();
    },

    rowClicked(row) {
      if (this.organizationsView) {
        this.organizationId = row.id;
        this.storeId = null;
      } else if (this.storesView) {
        this.storeId = row.id;
      }
      this.updateQueryParams();
    },

    sortChanged(sortParams) {
      this.sortParams = sortParams;
      this.updateQueryParams();
    },

    updateDate(date) {
      this.date = date;
      this.updateQueryParams();
    },

    updateCurrentMonth(endDate) {
      const monthNames = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];
      const month = monthNames[endDate.getMonth()];
      const year = endDate.getFullYear();
      this.currentMonth = `${month} ${year}`;
    },

    onSalesFieldManagerChange(salesFieldManagerId) {
      this.salesFieldManagerId = salesFieldManagerId;
      this.updateQueryParams();
    },

    resetToBeginning() {
      this.date = null;
      this.updateQueryParams();
    },
  },

  watch: {
    "$route.query"() {
      this.loadQueryParams();
      this.getAnalytics();
    },
  },
};
</script>
