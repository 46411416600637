<template>
  <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-12 w-auto" src="@/assets/large_logo_estaly_alternative.png" alt="Estaly" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {{ $t("Sign in to your account") }}
        </h2>
      </div>
      <VeeForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
        <form class="mt-8 space-y-6" @submit="handleSubmit($event, handleSignin)">
          <Field type="hidden" name="remember" value="true" />
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <label for="email-address" class="sr-only">{{ $t("Email address") }}</label>
              <Field
                id="email-address"
                name="email"
                type="email"
                autocomplete="email"
                required=""
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                placeholder="Email address"
                v-model="user.email"
              />
              <ErrorMessage name="email" class="mt-2 text-sm text-red-600" />
            </div>
            <div>
              <div class="flex">
                <label for="password" class="sr-only">{{ $t("Password") }}</label>
                <Field
                  v-if="showPassword"
                  id="password"
                  name="password"
                  autocomplete="current-password"
                  type="text"
                  required=""
                  class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-bl-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm border-r-0"
                  placeholder="Password"
                  v-model="user.password"
                />
                <Field
                  v-else
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required=""
                  class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-bl-md rounded-r-none focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm border-r-0"
                  placeholder="Password"
                  v-model="user.password"
                />
                <div
                  v-if="showPassword"
                  class="cursor-pointer bg-white appearance-none rounded-none relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm border-l-0 rounded-l-none"
                  @click="toggleShow"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                    <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                    <path
                      fill-rule="evenodd"
                      d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div
                  v-else
                  class="cursor-pointer bg-white appearance-none rounded-none relative block px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm border-l-0 rounded-l-none"
                  @click="toggleShow"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                    <path
                      d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z"
                    />
                    <path
                      d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z"
                    />
                    <path
                      d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z"
                    />
                  </svg>
                </div>
              </div>
              <ErrorMessage name="password" class="mt-2 text-sm text-red-600" />
            </div>
          </div>
          <div>
            <button
              type="submit"
              class="group relative w-full flex justify-center inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow disabled:cursor-wait disabled:opacity-75 text-button-font-primary bg-primary uppercase"
            >
              <span class="absolute left-0 inset-y-0 flex items-center pl-3"></span>
              {{ $t("Sign in") }}
            </button>
          </div>
        </form>
      </VeeForm>
      <div>
        <p>
          <span class="text-sm font-bold cursor-pointer text-primary-dark" @click="handleForgotPassword">
            Mot de passe oublié ?
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import User from "../../models/user";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  components: {
    Field,
    VeeForm,
    ErrorMessage,
  },

  data() {
    const schema = yup.object({
      email: yup.string().required("Email is required").email("Email is wrong"),
      password: yup.string().required("Password is required").min(8, "Password is too short"),
    });

    return {
      user: new User("", ""),
      schema,
      showPassword: false,
      storeId: null,
    };
  },

  computed: {
    ...mapState("auth", ["currentUser"]),
    ...mapState("auth", ["signedIn"]),
    ...mapGetters("auth", ["isEstalyManager"]),
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },

  created() {
    if (this.signedIn) {
      this.$router.push("/");
    }
  },

  methods: {
    ...mapActions("auth", ["signin"]),
    ...mapActions("notifications", ["notify"]),

    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    async handleSignin() {
      if (this.user.email && this.user.password) {
        try {
          await this.signin(this.user);
          if (!this.isEstalyManager) {
            this.notify({
              type: "error",
              category: "simple",
              title: "You are trying to connect to the wrong URL.",
              text: "Please try to connect at store.app.estaly.co",
            });
          } else {
            this.$router.push("/");
          }
        } catch (error) {
          const statusCode = error.response.status;
          switch (statusCode) {
            case 404:
              this.notify({
                type: "error",
                category: "simple",
                title: "Your password is wrong.",
                text: "Please try again.",
              });
          }
        }
      }
    },

    async handleForgotPassword() {
      await this.$router.push("/forgot-password");
    },
  },
};
</script>
