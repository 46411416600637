<template>
  <div>
    <Header />
    <Store />
  </div>
</template>

<script>
import Header from "@/components/partners/Header.vue";
import Store from "@/views/stores/Store.vue";

export default {
  components: {
    Header,
    Store,
  },
};
</script>
