<template>
  <div>
    <div v-if="claims.length === 0" class="text-center mt-4 py-2">
      <p>Aucun sinistre</p>
    </div>
    <div v-else>
      <div class="flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr class="hidden md:table-row">
                    <th
                      scope="col"
                      class="py-3 pl-4 pr-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {{ $t("Claim Id") }}
                    </th>
                    <th
                      scope="col"
                      class="py-3 pl-2 pr-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Nom du magasin
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Nom du produit
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Date de déclaration
                    </th>

                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      Client
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {{ $t("Customer email") }}
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr
                    v-for="(claim, id) in claims"
                    v-bind:key="id"
                    @click="goToClaim(claim)"
                    class="cursor-pointer hover:bg-gray-100"
                  >
                    <td v-bind:class="['whitespace-nowrap py-4 pl-4 pr-2 text-xs font-medium text-gray-900 sm:pl-4']">
                      <div class="flex flex-col">
                        <div class="flex items-center">
                          <router-link @click.stop :to="claimPath(claim)">{{ claim.friendlyId }}</router-link>
                        </div>
                        <div class="md:hidden font-light">
                          {{ this.getFormattedDate(claim.filedDate) }}
                        </div>
                        <div class="md:hidden font-light">
                          {{ claim.customerEmail }}
                        </div>
                        <div class="md:hidden font-light">
                          {{ claim.productTitle }}
                        </div>
                        <div class="md:hidden font-light">
                          {{ claim.salesChannel }}
                        </div>
                        <div class="mt-2">
                          <span
                            v-bind:class="[
                              getClaimStatusBannerStyle(claim.status.color),
                              'inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium',
                            ]"
                          >
                            {{ claim.status.text }}
                          </span>

                          <span
                            v-if="claim.requiredAction"
                            v-bind:class="[
                              'bg-red-400 text-white',
                              'ml-2 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium',
                            ]"
                          >
                            !
                          </span>
                        </div>
                      </div>
                    </td>
                    <td class="hidden md:table-cell whitespace-nowrap py-4 pl-2 pr-2 text-xs text-gray-500">
                      {{ claim.salesChannel }}
                    </td>
                    <td class="hidden md:table-cell py-4 pl-2 pr-2 text-xs text-gray-500">
                      {{ claim.productTitle }}
                    </td>
                    <td class="hidden md:table-cell whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                      {{ this.getFormattedDate(claim.filedDate) }}
                    </td>

                    <td class="hidden md:table-cell whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                      {{ claim.customerFirstName }}
                      {{ claim.customerLastName }}
                    </td>
                    <td class="hidden md:table-cell whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                      {{ claim.customerEmail }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["claims", "currentPage", "totalNumberOfClaims"],

  methods: {
    getClaimStatusBannerStyle: function (color) {
      return `bg-${color}-100 text-${color}-800`;
    },

    goToClaim(claim) {
      this.$router.push(this.claimPath(claim));
    },

    claimPath(claim) {
      return `/claims/${claim.friendlyId}`;
    },

    getFormattedDate: function (dateToFormat) {
      const currentLocale = this.$i18n.locale;
      return dateToFormat !== null ? new Date(dateToFormat).toLocaleDateString(currentLocale) : null;
    },
  },
};
</script>
