<template>
  <Modal :open="open" @close="close">
    <div>
      <div>
        <div v-if="approveInvoice">
          <p class="text-center font-medium pb-4">Veuillez approuver ou refuser la facture du sinistre.</p>
          <div class="flex justify-center">
            <button
              class="mx-1 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-green-600 bg-green-100 transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-green-100"
              @click="doAction('actionApproveInvoice')"
            >
              {{ $t("Approuver la facture") }}
            </button>
            <button
              class="mx-1 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-red-600 bg-red-100 transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-red-100"
              @click="doAction('actionRefuseInvoice')"
            >
              {{ $t("Refuser la facture") }}
            </button>
          </div>
        </div>
        <div v-else-if="approveRepairQuotation">
          <p class="text-center font-medium pb-4">Veuillez approuver ou refuser le devis de réparation.</p>
          <div class="text-center pb-4">
            <span class="mx-2 flex-1 w-0 truncate">{{ claim.quotation.fileName }}</span>
            <a
              class="inline-flex p-1.5 border border-transparent rounded-full shadow-sm text-white bg-primary"
              :href="claim.quotation.path"
              target="_blank"
            >
              <EyeIcon class="h-4 w-4" aria-hidden="true" />
            </a>
          </div>
          <div class="flex justify-center">
            <button
              class="mx-1 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-green-600 bg-green-100 transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-green-100"
              @click="doAction('actionApproveQuotation')"
            >
              {{ $t("Approuver le devis") }}
            </button>
            <button
              class="mx-1 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-red-600 bg-red-100 transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-red-100"
              @click="doAction('actionRefuseQuotation')"
            >
              {{ $t("Refuser le devis") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/modal/Modal.vue";
import { EyeIcon } from "@heroicons/vue/solid";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Modal,
    EyeIcon,
  },

  props: ["open", "claim"],

  computed: {
    ...mapGetters("claims", ["approveRepairQuotation"]),

    approveRepairQuotation() {
      return this.claim.requiredAction === "approve_quotation";
    },

    approveInvoice() {
      return this.claim.requiredAction === "approve_invoice";
    },
  },

  methods: {
    ...mapActions("claims", [
      "actionApproveQuotation",
      "actionRefuseQuotation",
      "actionApproveInvoice",
      "actionRefuseInvoice",
    ]),
    ...mapActions("notifications", ["notify"]),

    doAction: async function (action) {
      switch (action) {
        case "actionApproveQuotation":
          try {
            await this.actionApproveQuotation();
          } catch (error) {
            await this.notify({
              category: "simple",
              type: "error",
              title: "Error when trying to approve the quotation",
              text: error.response.data.error,
            });
          }
          break;
        case "actionRefuseQuotation":
          try {
            await this.actionRefuseQuotation();
          } catch (error) {
            await this.notify({
              category: "simple",
              type: "error",
              title: "Error when trying to refuse the quotation",
              text: error.response.data.error,
            });
          }
          break;
        case "actionApproveInvoice":
          try {
            await this.actionApproveInvoice();
          } catch (error) {
            await this.notify({
              category: "simple",
              type: "error",
              title: "Error when trying to approve the invoice",
              text: error.response.data.error,
            });
          }
          break;
        case "actionRefuseInvoice":
          try {
            await this.actionRefuseInvoice();
          } catch (error) {
            await this.notify({
              category: "simple",
              type: "error",
              title: "Error when trying to refuse the invoice",
              text: error.response.data.error,
            });
          }
          break;
      }

      this.close();
    },

    close: function () {
      this.$emit("close");
    },
  },
};
</script>
