<template>
  <div>
    <PlanSalesHeader />
    <PlanSalesMenu @updatePage="updatePage" />
    <PlanSalesContent :current-page="currentPage" @updatePage="updatePage" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PlanSalesContent from "@/views/plan_sales/list/PlanSalesContent.vue";
import PlanSalesHeader from "@/views/plan_sales/list/PlanSalesHeader.vue";
import PlanSalesMenu from "@/views/plan_sales/list/PlanSalesMenu.vue";

export default {
  components: {
    PlanSalesMenu,
    PlanSalesHeader,
    PlanSalesContent,
  },

  data: () => {
    return {
      currentPage: 1,
    };
  },

  computed: {
    ...mapState("auth", ["signedIn"]),
  },

  created() {
    if (this.signedIn) {
      this.retrievePlanSales();
    } else {
      this.$router.push("/signin");
    }
  },

  methods: {
    ...mapActions("plan_sale_list", ["getPlanSales"]),

    async retrievePlanSales() {
      try {
        await this.getPlanSales({ page: this.currentPage });
      } catch (error) {
        console.log(error);
      }
    },

    updatePage(page, refresh = true) {
      this.currentPage = page;

      if (refresh) {
        this.getPlanSales({ page: this.currentPage });
      }
    },
  },
};
</script>
