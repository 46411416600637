<template>
  <CollapsableContent :title="$t('Users')">
    <template #header>
      <GenericButton @click="() => openCreateModal()">
        {{ $t("Add a user") }}
      </GenericButton>
    </template>
    <div class="overflow-x-auto">
      <div class="inline-block min-w-full align-middle">
        <div v-if="anyUsersInformations">
          <div class="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ $t("Id") }}
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ $t("Name") }}
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ $t("Email") }}
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ $t("Role") }}
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {{ $t("Activated") }}
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" />
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" />
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200 text-sm">
                <tr v-for="user in userList" :key="user.id" :class="'hover:bg-gray-100'">
                  <td class="px-3 py-3.5 font-bold">
                    <span v-if="user.user_id !== null">
                      {{ user.user_id }}
                    </span>
                    <span v-else>-</span>
                  </td>
                  <td class="px-3 py-3.5">
                    {{ user.name }}
                  </td>
                  <td class="px-3 py-3.5">
                    {{ user.email }}
                  </td>
                  <td class="px-3 py-3.5">
                    {{ $t("roles." + user.role) }}
                  </td>
                  <td class="px-3 py-3.5">
                    <FieldSpan
                      v-if="user.activated !== null"
                      :style-to-add="['text-white', user.activated ? 'bg-green-400' : 'bg-red-400']"
                      :value="user.activated ? 'Actif' : 'Inactif'"
                    />
                    <span v-else>-</span>
                  </td>
                  <td class="px-3 py-3.5">
                    <PencilIcon
                      class="h-5 cursor-pointer"
                      v-if="user.user_id"
                      @click="() => openUpdateModal(user.user_id)"
                    />
                  </td>
                  <td class="px-3 py-3.5">
                    <TrashIcon class="h-5 cursor-pointer" v-if="user.user_id" @click="() => deleteUser(user.user_id)" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <div class="bg-gray-50 p-6">
            <p>{{ $t("No data to display") }}</p>
          </div>
        </div>
      </div>
    </div>

    <UpdateUserModal
      :open-modal="openedUpdateModal"
      :edited-user-id="editedUserId"
      @closeModal="closeUpdateModal"
      @userListUpdated="refreshOrganizationUsers"
      :organization-id="organizationId"
    />
  </CollapsableContent>
</template>

<script>
import { PencilIcon, TrashIcon } from "@heroicons/vue/outline";
import FieldSpan from "@/components/utils/FieldSpan.vue";
import CollapsableContent from "@/components/utils/CollapsableContent.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import BackofficeService from "@/api/services/backoffice";
import { toSnakeCase } from "@/utils/utils";
import { mapActions } from "vuex";
import UpdateUserModal from "@/views/stores/details/users/UpdateUserModal.vue";

export default {
  components: {
    UpdateUserModal,
    CollapsableContent,
    PencilIcon,
    TrashIcon,
    GenericButton,
    FieldSpan,
  },

  props: {
    usersInformations: {
      type: Array,
      required: true,
    },
    organizationId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      userList: [],
      openedUpdateModal: false,
      editedUserId: null,
    };
  },

  watch: {
    usersInformations() {
      this.userList = this.usersInformations;
    },
  },

  computed: {
    anyUsersInformations() {
      return this.usersInformations?.length > 0;
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async openCreateModal() {
      this.openedUpdateModal = true;
      this.editedUserId = null;
    },

    openUpdateModal(user) {
      this.openedUpdateModal = true;
      this.editedUserId = user;
    },

    closeUpdateModal() {
      this.openedUpdateModal = false;
      this.editedUserId = null;
    },

    async refreshOrganizationUsers() {
      this.openedUpdateModal = false;
      const response = await BackofficeService.getUsers({
        organizationId: this.organizationId,
      });
      this.userList = toSnakeCase(response.data.users.organizationManagers.concat(response.data.users.storeManagers));
    },

    async deleteUser(userId) {
      if (!confirm("Do you really want to delete this user?")) {
        return;
      }

      try {
        await BackofficeService.deleteUser({
          userId,
          organizationId: this.organizationId,
        });
        this.notify({
          category: "simple",
          type: "success",
          title: "User deleted successfully",
        });
        await this.refreshOrganizationUsers();
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "User could not be deleted",
          text: error.response.data.errors.join(", "),
        });
      }
    },
  },
};
</script>
