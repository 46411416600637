<template>
  <div v-if="isProductCategoryUpdatable">
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton
          class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100"
        >
          {{ $t(`${this.product.category}`) }}
          <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute overflow-y-auto right-0 z-10 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto"
        >
          <div class="py-1">
            <MenuItem v-for="(category, idx) in categories" v-bind:key="idx" v-slot="{ active }">
              <button
                @click="updateCategory(category)"
                :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm', 'w-full']"
              >
                <p class="flex">
                  {{ $t(`${category}`) }}
                </p>
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
  <div v-else>
    <p>{{ $t(`${this.product.category}`) }}</p>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import { isObjectValid } from "@/utils/validation";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },

  props: ["product"],

  computed: {
    ...mapState("backoffice", ["availableFilters"]),

    isProductCategoryUpdatable: function () {
      return (
        isObjectValid(this.product) &&
        this.product.contractStatus === "pending" &&
        this.product.enabled === false &&
        this.product.approvalStatus === "to_be_approved"
      );
    },

    categories: function () {
      return this.availableFilters.categories;
    },
  },

  methods: {
    ...mapActions("backoffice", ["updateProductCategory"]),

    updateCategory: async function (category) {
      await this.updateProductCategory({
        product: this.product,
        newCategory: category,
      });
    },
  },
};
</script>
