<template>
  <div>
    <Header />
    <RetailAnalytics />
  </div>
</template>

<script>
import Header from "@/components/analytics/Header.vue";
import RetailAnalytics from "@/components/analytics/retail/Analytics.vue";

export default {
  components: {
    Header,
    RetailAnalytics,
  },
};
</script>
