<template>
  <CollapsableContent title="Coordonnées du magasin" :open="false">
    <template #header>
      <StoreConfigurationStatusBadge :configuration-status="contactInformations.contactInformationsConfigStatus" />
    </template>
    <dl class="px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.email") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ contactInformations.Email }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.referent_last_name") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ contactInformations.referentLastName }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.referent_first_name") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ contactInformations.referentFirstName }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.referent_email") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ contactInformations.referentEmail }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.referent_phone") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ contactInformations.referentPhone }}
          </p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("store.referent_role") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p>
            {{ contactInformations.referentRole }}
          </p>
        </dd>
      </div>
    </dl>
    <div class="p-3 flex flex-row-reverse gap-x-4" v-if="isStoreSetup">
      <GenericButton @click="validate">Valider</GenericButton>
      <GenericButton @click="reject">Refuser</GenericButton>
    </div>
  </CollapsableContent>
</template>

<script>
import CollapsableContent from "@/components/utils/CollapsableContent.vue";
import StoreConfigurationStatusBadge from "@/components/StoreConfigurationStatusBadge.vue";
import GenericButton from "@/components/utils/GenericButton.vue";

export default {
  components: {
    CollapsableContent,
    StoreConfigurationStatusBadge,
    GenericButton,
  },
  props: ["contactInformations", "isStoreSetup"],
  methods: {
    validate() {
      this.$emit("validate");
    },
    reject() {
      this.$emit("reject");
    },
  },
};
</script>
