<template>
  <div>
    <Header />
    <Organizations />
  </div>
</template>

<script>
import Header from "@/components/partners/Header.vue";
import Organizations from "@/views/organizations/Organizations.vue";

export default {
  components: {
    Header,
    Organizations,
  },
};
</script>
