<template>
  <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img
          class="mx-auto h-14 w-auto"
          src="https://estaly-docs.s3.eu-west-3.amazonaws.com/Logo_Estaly+-+MiniatureBleu.png"
          alt="Estaly"
        />
        <h2 class="mt-6 text-center text-3xl font-normal text-gray-900">Réinitialiser votre mot de passe</h2>
      </div>
      <VeeForm v-slot="{ handleSubmit }" v-bind:validation-schema="schema" as="div">
        <form class="mt-8 space-y-6" @submit="handleSubmit($event, handleResetPassword)">
          <Field type="hidden" name="remember" value="true" />
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <label for="password" class="sr-only">Mot de passe</label>
              <Field
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required=""
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                placeholder="Mot de passe"
                v-model="password"
              />
              <ErrorMessage name="password" />
            </div>
            <div>
              <label for="password" class="sr-only">Confirmation du mot de passe</label>
              <Field
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="password"
                autocomplete="current-password-confirmation"
                required=""
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                placeholder="Confirmation du mot de passe"
                v-model="passwordConfirmation"
              />
              <ErrorMessage class="font-sm text-red-600" name="passwordConfirmation" />
            </div>
          </div>
          <div>
            <input type="hidden" name="reset_password_token" v-model="resetPasswordToken" />
            <input type="hidden" name="email" v-model="email" />
          </div>
          <div>
            <button
              type="submit"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-button-font-primary bg-primary uppercase"
            >
              <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon class="h-5 w-5 text-primary-dark" aria-hidden="true" />
              </span>
              Sauvegarder
            </button>
          </div>
        </form>
      </VeeForm>
    </div>
  </div>
</template>

<script>
import { LockClosedIcon } from "@heroicons/vue/solid";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

const schema = yup.object({
  password: yup.string().required("Please enter your password").min(8, "Your password is too short"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Passwords must match")
    .required("New password confirmation is required"),
});

export default {
  components: {
    LockClosedIcon,
    Field,
    VeeForm,
    ErrorMessage,
  },

  data() {
    return {
      password: "",
      passwordConfirmation: "",
      email: this.$route.query.email,
      resetPasswordToken: this.$route.params.id,
      loading: false,
      message: "",
      schema,
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("auth", ["resetPassword"]),

    async handleResetPassword() {
      let payload = {
        email: this.email,
        user: {
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        },
      };
      try {
        await this.resetPassword({ resetPasswordToken: this.resetPasswordToken, payload });
        this.notify({
          type: "success",
          category: "simple",
          title: "Votre mot de passe a été réinitialisé avec succès.",
        });
        this.$router.push("/");
      } catch (error) {
        this.notify({
          type: "error",
          category: "simple",
          title: this.$t("An error occurred"),
          text: error.response?.data?.error || error.message,
        });
      }
    },
  },
};
</script>
