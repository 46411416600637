import BackofficeService from "@/api/services/backoffice";

const initialState = {
  availableFilters: {
    billing: [],
    chargedBy: [],
    status: [],
    organizations: [],
    stores: [],
  },
  selectedFilters: {
    billing: [],
    chargedBy: [],
    status: [],
    organizations: [],
    stores: [],
  },
  planSales: [],
  totalNumberOfPlanSales: null,
  keywords: "",
  startDate: null,
  endDate: null,
};

export default {
  namespaced: true,

  state: initialState,

  getters: {},

  mutations: {
    setPlanSales(state, { planSales, totalNumberOfPlanSales }) {
      state.planSales = planSales;
      state.totalNumberOfPlanSales = totalNumberOfPlanSales;
    },

    setFilter(state, { filterName, filterOptions }) {
      state.selectedFilters[filterName] = filterOptions;
    },

    setKeywords(state, keywords) {
      state.keywords = keywords;
    },

    setAvailableFilters(state, { billing, chargedBy, status, organizations, stores }) {
      state.availableFilters.billing = billing;
      state.availableFilters.chargedBy = chargedBy;
      state.availableFilters.status = status;
      state.availableFilters.organizations = organizations;
      state.availableFilters.stores = stores;
    },

    setDates(state, { startDate, endDate }) {
      state.startDate = startDate;
      state.endDate = endDate;
    },
  },

  actions: {
    async getPlanSales({ state, commit }, { page }) {
      try {
        const response = await BackofficeService.getPlanSales({
          page: page,
          billing: state.selectedFilters.billing,
          charged_by: state.selectedFilters.chargedBy,
          status: state.selectedFilters.status,
          organizations: state.selectedFilters.organizations,
          stores: state.selectedFilters.stores,
          keywords: state.keywords,
          start_date: state.startDate,
          end_date: state.endDate,
        });
        commit("setPlanSales", {
          planSales: response.data.planSales,
          totalNumberOfPlanSales: response.data.totalNumberOfPlanSales,
        });

        commit("setAvailableFilters", {
          billing: response.data.filters.billing,
          chargedBy: response.data.filters.chargedBy,
          status: response.data.filters.status,
          organizations: response.data.filters.organizations,
          stores: response.data.filters.stores,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    updateSearch({ commit, dispatch }, { searchString, page }) {
      commit("setKeywords", searchString);
      dispatch("getPlanSales", {
        page: page,
      });
    },

    updateFilter({ commit, dispatch }, { filterName, filterOptions, page }) {
      commit("setFilter", { filterName, filterOptions });
      dispatch("getPlanSales", {
        page: page,
      });
    },

    updateDateFilter({ commit, dispatch }, { startDate, endDate, page }) {
      commit("setDates", { startDate, endDate });
      dispatch("getPlanSales", {
        page: page,
      });
    },
  },
};
