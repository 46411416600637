<template>
  <CollapsableContent title="Informations sur la facturation" :open="contentOpen">
    <template #header>
      <div v-if="remunerationModelEditable">
        <Selector
          v-if="showRemunerationModelSelector"
          id="remuneration_model"
          name="remuneration_model"
          v-model="remunerationModel"
          :options="remunerationModelOptions"
        />
        <GenericButton v-else @click="editRemunerationModel">Modifier le modèle de rémunération</GenericButton>
      </div>
    </template>
    <dl class="px-4 py-5 grid grid-cols-2 gap-x-4 gap-y-8">
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("Entity") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p v-if="entity">
            {{ entity }}
          </p>
          <p class="font-bold" v-else>—</p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">{{ $t("Address") }}</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p v-if="address">
            {{ address }}
          </p>
          <p class="font-bold" v-else>—</p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Remuneration model") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ t_remuneration_model(remunerationModel) }}
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Advance months commission") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p v-if="advanceMonthsCommission">
            {{ advanceMonthsCommission }}
          </p>
          <p class="font-bold" v-else>—</p>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Billing delay") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ billingDelay }}
        </dd>
      </div>
      <div>
        <dt class="text-sm font-bold text-gray-500">
          {{ $t("Churn rate") }}
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <p v-if="churnRate">
            {{ churnRate }}
          </p>
          <p class="font-bold" v-else>—</p>
        </dd>
      </div>
    </dl>
  </CollapsableContent>
</template>

<script>
import CollapsableContent from "@/components/utils/CollapsableContent.vue";
import GenericButton from "@/components/utils/GenericButton.vue";
import Selector from "@/components/utils/Selector.vue";
import BackofficeService from "@/api/services/backoffice.js";
import { mapActions } from "vuex";

export default {
  components: {
    CollapsableContent,
    GenericButton,
    Selector,
  },

  props: ["store"],

  data() {
    return {
      contentOpen: false,
      showRemunerationModelSelector: false,
      remunerationModelValue: this.remunerationModel,
      remunerationModelOptions: [
        { value: "classic", label: this.t_remuneration_model("classic") },
        {
          value: "advance_commission_without_max_churn",
          label: this.t_remuneration_model("advance_commission_without_max_churn"),
        },
        {
          value: "advance_commission_with_max_churn",
          label: this.t_remuneration_model("advance_commission_with_max_churn"),
        },
      ],
    };
  },

  computed: {
    billingConfiguration() {
      return this.store.billingConfiguration;
    },
    entity() {
      return this.billingConfiguration?.entity;
    },
    address() {
      return this.billingConfiguration?.address;
    },
    remunerationModel: {
      get() {
        return this.billingConfiguration?.remunerationModel;
      },
      set(value) {
        this.remunerationModelValue = value;
      },
    },
    advanceMonthsCommission() {
      return this.billingConfiguration?.advanceMonthsCommission;
    },
    billingDelay() {
      return this.billingConfiguration?.billingDelay;
    },
    churnRate() {
      return this.billingConfiguration?.churnRate;
    },
    remunerationModelEditable() {
      return this.store.status === "setup";
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    editRemunerationModel() {
      this.contentOpen = true;
      this.showRemunerationModelSelector = true;
    },

    t_remuneration_model(value) {
      return this.$t(`store.remuneration_model.${value}`);
    },

    async updateRemunerationModel() {
      try {
        await BackofficeService.updateStore(this.store.id, { remunerationModel: this.remunerationModelValue });
        await this.notify({
          category: "simple",
          type: "success",
          title: "Mise à jour effectuée",
        });
        this.$emit("remunerationModelUpdated");
      } catch (error) {
        console.error(error);
        await this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur est survenue",
          text: error.response?.data?.error || error.message,
        });
      }
    },
  },

  watch: {
    async remunerationModelValue(newValue) {
      if (newValue) await this.updateRemunerationModel();
    },
  },
};
</script>
