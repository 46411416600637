<template>
  <p v-if="isPresent(value)">{{ value }}</p>
  <p v-else class="font-bold">—</p>
</template>

<script>
import { isPresent } from "@/utils/validation";

export default {
  props: ["value"],
  methods: {
    isPresent,
  },
};
</script>
